<template>
    <div>
        <div align="center" class="my-5" v-if="dati_caricati === false">
            <b-spinner
            style="width: 3rem; height: 3rem;"
            class="mr-1"
            variant="primary"
            />
        </div>

        <section class="mb-4" v-if="dati_caricati === true">
            <b-card>

              <div class="d-flex  align-items-center justify-content-between bd-highlight pb-2">
                  <b-card-title class="mt-0 mb-0">{{ datiazienda.infoazienda.nome }}</b-card-title>
                  
                  <b-button
                  variant="outline-success"
                  :to="{ name: 'crm-azienda-utenti-nuovo' }"
                  >Nuovo Utente
                  </b-button>
              </div>
        
              <div v-if="datiazienda.utenti.length !== 0">
                <b-table outlined :fields="fields" :items="datiazienda.utenti" :tbody-tr-class="rowClass" responsive="sm">
                    <template #cell(nome)="data">
                        <span class="font-weight-bolder">
                        {{ data.item.nome }}
                        </span><br />
                        <span class="font-small-2 text-muted">
                        {{ data.item.email }}
                        </span>
                    </template>

                    <template #cell(attivo)="data">
                        <span class="font-weight-bolder">
                        {{ data.item.attivo_nome }}
                        </span>
                    </template>

                    <template #cell(operazioni)="data">
                        <div class="text-nowrap">
                            <feather-icon
                                :id="`utente-riga-${data.item.id}-preview`"
                                icon="InfoIcon"
                                class="cursor-pointer mr-1"
                                size="21"
                                @click="apriPreview(data.item.id)"
                            />
                            <b-tooltip
                                title="preview"
                                class="cursor-pointer"
                                :target="`utente-riga-${data.item.id}-preview`"
                            />
                            <feather-icon
                                :id="`utente-riga-${data.item.id}-stato`"
                                icon="RefreshCwIcon"
                                class="cursor-pointer mr-1"
                                size="21"
                                @click="aggiornaStatoUtente(data.item.id,data.item.attivo)"
                            />
                            <b-tooltip
                                title="cambio stato"
                                class="cursor-pointer"
                                :target="`utente-riga-${data.item.id}-stato`"
                            />
                            <feather-icon
                                :id="`utente-riga-${data.item.id}-edit`"
                                icon="EditIcon"
                                class="cursor-pointer mr-1"
                                size="21"
                                @click="$router.push({ name: 'crm-azienda-utenti-modifica', params: { id_utente: data.item.id }})"
                            />
                            <b-tooltip
                                title="aggiorna utente"
                                class="cursor-pointer"
                                :target="`utente-riga-${data.item.id}-edit`"
                            />
                        </div>
                    </template>

                </b-table>

                <b-modal ref="my-modal" hide-footer centered title="Informazioni Articolo" modal-class="modal-primary" size="lg">
                <div class="d-block text-center">
                    <b-row class="pb-2 text-left">
                        <b-col md="4" lg="3">Nome</b-col>
                        <b-col md="8" lg="9">{{ infomodal.nome }}</b-col>
                    </b-row>
                    <b-row class="pb-2 text-left">
                        <b-col md="4" lg="3">Tipo di utente</b-col>
                        <b-col md="8" lg="9">{{ infomodal.nome_tipo_utente }}</b-col>
                    </b-row>
                    <b-row class="pb-2 text-left">
                        <b-col md="4" lg="3">Username di accesso</b-col>
                        <b-col md="8" lg="9">{{ infomodal.codice }}</b-col>
                    </b-row>
                    <b-row class="pb-2 text-left">
                        <b-col md="4" lg="3">Email</b-col>
                        <b-col md="8" lg="9">{{ infomodal.email }}</b-col>
                    </b-row>
                    <b-row class="pb-2 text-left">
                        <b-col md="4" lg="3">Stato</b-col>
                        <b-col md="8" lg="9">{{ statusAttivo(infomodal.attivo) }}</b-col>
                    </b-row>
                    <b-row class="pb-2 text-left">
                        <b-col md="4" lg="3">Telefono</b-col>
                        <b-col md="8" lg="9">{{ infomodal.telefono }}</b-col>
                    </b-row>
                    <b-row class="pb-2 text-left">
                        <b-col md="4" lg="3">Telefono 2</b-col>
                        <b-col md="8" lg="9">{{ infomodal.telefono2 }}</b-col>
                    </b-row>
                </div>
                </b-modal>

              </div>

            </b-card>
        </section>

    </div>
  
</template>

<script>
import router from '@/router'
import { BRow, BCol, BCard, BTooltip, BModal, VBModal, BTable, BSpinner, BCardTitle, BButton } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,

    BCard,
    BTooltip,
    BModal, 
    VBModal,
    BTable,
    BSpinner,
    BCardTitle,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      datiazienda: {
        dati_fiscali: { },
        utenti: { },
      },
      dati_caricati: false,

      fields: [
        {
          key: 'nome',
          label: 'Info Utente',
          sortable: false
        },
        {
          key: 'attivo',
          label: 'Stato',
          sortable: false
        },
        {
          key: 'operazioni',
          label: 'Operazioni',
          sortable: false
        }
      ],

      infomodal: {
        nome: '',
        nome_tipo: '',
        codice: '',
        email: '',
        attivo: '',
        telefono: '',
        telefono2: '',
      },
    }
  },
  computed: {
    statusAttivo() {
      const statusColor = {
        /* eslint-disable key-spacing */
        '1' : 'Accesso al CRM Attivo',
        '0' : 'Accesso al CRM Disattivato',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  created() {
    this.estraiDati(router.currentRoute.params.id_azienda);
    
  },
  methods: {
    estraiDati(id_azienda){
        this.$http.get('v2/crmadmin/utentiaziendainfo/'+id_azienda)
            .then(response => {
                if(router.currentRoute.params.id_azienda == response.data.infoazienda.id){
                    this.datiazienda = response.data
                    this.dati_caricati = true;
                    //console.log(response.datiazienda);
                }
            })
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.attivo == 1) return 'table-success'
      if (item.attivo == 0) return 'table-danger'
    },
    aggiornaStatoUtente(id_utente,attivo){
        console.log("id_utente -> "+id_utente+"\nstato -> "+attivo)
        let cambio_stato;
        if(attivo == '1'){
          cambio_stato = 0
        } else {
          cambio_stato = 1
        }

        this.$http.get('v2/crmadmin/utentiaziendacambiostato/'+id_utente+"/"+cambio_stato)
            .then(response => {

              console.log(response)

              if(response.data.success.tipo == 'OK'){
                this.dati_caricati = false
                this.showToast('success','CheckCircleIcon',response.data.success.descrizione,'','top-center')

                this.estraiDati(router.currentRoute.params.id_azienda);
              } else {
                //errore aggiornamento
                this.showToast('error','AlertCircleIcon',response.data.success.descrizione,'','top-center')
              }
                
            })
    },
    showToast(variant, icona, titolo, testo, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titolo,
          icon: icona,
          text: testo,
          variant,
        },
      },
      {
        position,
      })
    },
    apriPreview(id){
      this.$http.get('v2/crmadmin/singolo_utente/'+id)
        .then(response => { 
            console.log("risposta server --------")
            console.log(response.data)

            if(response.data.statusCode===200){
            //risposta positiva
                this.infomodal = { nome: response.data.dati.nome,
                nome_tipo_utente: response.data.dati.nome_tipo,
                codice: response.data.dati.codice,
                email: response.data.dati.email,
                attivo: response.data.dati.attivo,
                telefono: response.data.dati.telefono,
                telefono2: response.data.dati.telefono2 };

                this.$refs['my-modal'].show()
            } else {
            //risposta negativa (errore sul server)
            
            }
        }).catch(e => {
            console.log(e);
        });
    },
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
